<template>
  <q-card flat class="q-pa-md q-ma-md">
    <q-form @submit.prevent="includeSites">
      <div class="q-pa-sm row justify-around q-gutter-x-sm">
        <q-card flat bordered class="col">
          <q-card-section class="bg-primary text-white">
            <div class="top-card-label">Selecione os canais</div>
          </q-card-section>

          <q-card-section>
            <q-select
              v-model="sites"
              label="Escolha um canal..."
              multiple
              use-chips
              outlined
              dense
              use-input
              input-debounce="200"
              @filter="filterSites"
              :options="sitesFiltered"
              option-label="name"
              option-value="id"
            >
              <template v-slot:selected-item="scope">
                <q-chip
                  removable
                  size="0.8rem"
                  @remove="scope.removeAtIndex(scope.index)"
                  :tabindex="scope.tabindex"
                  color="primary"
                  text-color="white"
                  square
                  icon-remove="close"
                  input-class="text-caption"
                >
                {{ scope.opt.name }}
                </q-chip>
              </template>
            </q-select>
          </q-card-section>
          <q-card-section class="scroll">
            <q-list bordered v-show="sites.length > 0" class="scroll" style="max-height: 200px">
              <q-item v-for="(item, index) in sites" :key="index">
                <q-item-section>
                  <q-item-label>{{ item.name }}</q-item-label>
                </q-item-section>
                <q-item-section avatar>
                  <q-btn icon="mdi-close" flat dense round @click="removeSite(index)" />
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
        </q-card>

        <q-card flat bordered class="col">
          <q-card-section class="bg-purple text-white">
            <div class="top-card-label">Selecione as campanhas</div>
          </q-card-section>

          <q-card-section>
            <q-select
              v-model="campaigns"
              label="Escolha uma campanha..."
              multiple
              use-chips
              outlined
              dense
              use-input
              input-debounce="200"
              @filter="filterCampaigns"
              :options="campaignsFiltered"
              option-label="name"
              option-value="id"
              color="purple"
            >
              <template v-slot:selected-item="scope">
                <q-chip
                  removable
                  size="0.8rem"
                  @remove="scope.removeAtIndex(scope.index)"
                  :tabindex="scope.tabindex"
                  color="purple"
                  text-color="white"
                  square
                  icon-remove="close"
                  input-class="text-caption"
                >
                {{ scope.opt.name }}
                </q-chip>
              </template>
            </q-select>
          </q-card-section>

          <q-card-section class="scroll">
            <q-list bordered v-show="campaigns.length > 0" class="scroll" style="max-height: 200px">
              <q-item v-for="(item, index) in campaigns" :key="index">
                <q-item-section>
                  <q-item-label>{{ item.name }}</q-item-label>
                </q-item-section>
                <q-item-section avatar>
                  <q-btn icon="mdi-close" flat dense round @click="removeCampaign(index)" />
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
        </q-card>
      </div>

      <q-card-section class="text-center">
        <q-btn unelevated no-caps type="submit" color="positive" label="Aprovar Canais" />
      </q-card-section>
    </q-form>
  </q-card>
</template>

<script>
import ChannelService from '../../services/ChannelService';

export default {
  mixins: [ChannelService],

  data() {
    return {
      sites: [],
      sitesList: [],
      sitesFiltered: [],
      campaigns: [],
      campaignsList: [],
      campaignsFiltered: []
    };
  },

  methods: {
    async getSites() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getListChannels();
          if (status === 200) {
            this.sitesList = data.map(site => ({
                ...site,
                name: `${site.name} (id: ${site.id})`
            }));
            this.sitesFiltered = [...this.sitesList];
          }
      } catch (error) {
        console.error(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterSites(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        this.sitesFiltered = !needle
        ? this.sitesList
        : this.sitesList.filter(v => v.name.toLocaleLowerCase().includes(needle) || v.id.toString().includes(needle));
      });
    },

    async getCampaigns() {
      try {
        const { data, status } = await this.$http.get('/redirect?app=ADVERTISER&path=/api/campaign/allactive_and_pending');
          if (status === 200) {
            this.campaignsList = data.map(campaign => ({
              ...campaign,
              name: `${campaign.name} (id: ${campaign.id})`
            }));
            this.campaignsFiltered = [...this.campaignsList];
          }
      } catch (error) {
        console.error(error);
      }
    },

    filterCampaigns(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        this.campaignsFiltered = !needle
        ? this.campaignsList
        : this.campaignsList.filter(v => v.name.toLocaleLowerCase().includes(needle) || v.id.toString().includes(needle));
      });
    },

    async includeSites() {
      if (this.sites.length == 0 || this.campaigns.length == 0) {
        this.errorNotify('Preencha ao menos um canal e uma campanha');
        return;
      }

      try {
        this.onLoading(true);

        let request = {
          app: 'ADVERTISER',
          path: '/api/campaign/registra_canal',
          params: `{"data":  {
                        "site_ids": [${this.sites.map((item) => item.id)}],
                        "campaign_ids": [${this.campaigns.map((item) => item.id)}]
                    }}`
        };

        const { data, status } = await this.$http.post('/redirect', this.parseQs(request));
        if (status === 200) {
          this.successNotify(data.message);

          this.sites = [];
          this.campaigns = [];
          this.sitesFiltered = [];
          this.campaignsFiltered = [];
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    removeCampaign(index) {
      this.campaigns.splice(index, 1);
    },
    removeSite(index) {
      this.sites.splice(index, 1);
    }
  },

  created() {
    this.getSites();
    this.getCampaigns();
  }
};
</script>
