<template>
  <q-page padding>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Aprovar Canais</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
        <q-breadcrumbs-el label="Aprovar Canais" exact />
      </q-breadcrumbs>
    </q-toolbar>

    <q-card flat bordered class="q-mt-md">
      <q-tabs
        v-model="tab"
        dense
        className="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-tab name="approveChannels" label="Aprovar Canais" />
        <q-tab name="includeChannels" label="Incluir Canais" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab">
        <q-tab-panel name="approveChannels">
          <form-approve-sites />
        </q-tab-panel>
        <q-tab-panel name="includeChannels">
          <form-include-sites />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-page>
</template>

<script>
import FormIncludeSites from '../../components/campaign/FormIncludeSites.vue';
import FormApproveSites from '../../components/campaign/FormApproveSites.vue';

export default {
  components: { FormIncludeSites, FormApproveSites },
  name: 'PageIncludeSites',

  data() {
    return {
      tab: 'approveChannels'
    };
  }
};
</script>
